import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./Labs.settingsForm";
import axios from 'axios';
import PDFImage from 'images/pdf.svg'


/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const LabsComp = class extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      lab_order_results: {
        widget_data: [],
      },
    };
    this.props.onChange(this.state.lab_order_results);
  }
  getValue() {
    return this.state.lab_order_results;
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        lab_order_results: {
          widget_data: value?.widget_data || [],
        },
      });
    }
  }

  render() {
    $(".component-edit-container p.lead").html("Labs");
    console.log("Lab Order", this.state)
    return (
      <div id={this.state.component.key}  className="labs-order-result">
        <h3>Lab Orders</h3>
        <div className="row">
          <div className="col-sm-12">
            <div className="labs-order-widget">
              <div className="container mt-3">
                <div className="most-top">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="dropdown bootstrap-select">
                        <button type="button" className="btn dropdown-toggle btn-light" data-toggle="dropdown" role="button" data-id="change-result" >
                          <div className="filter-option">
                            <div className="filter-option-inner">
                              <div className="filter-option-inner-inner">
                                {this.state.lab_order_results  && this.state.lab_order_results.widget_data.length > 0 && (
                                    <>
                                      <span>{this.state.lab_order_results.widget_data[0].observation_date}</span>
                                      <span></span>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </button>
                        <div className="dropdown-menu " role="combobox">
                          <div className="bs-searchbox"><input type="text" className="form-control" autocomplete="off" role="textbox" aria-label="Search" /></div>
                          <div className="inner show" role="listbox" aria-expanded="false">
                            <ul className="dropdown-menu inner show"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 text-right">

                      PDF Results 
                      {this.state.lab_order_results  && this.state.lab_order_results.widget_data.length > 0 && (
                        <>
                          <a data-remote="true" href={`/labs/${this.state.lab_order_results.widget_data[0].lab_order_result_guid}/result_pdf`} target="_blank"><img src={PDFImage} /></a>
                          <span></span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div>
                    <div>
                      <table className="table table-condensed table-bordered">
                        <thead>
                          <tr>
                            <th>Test</th>
                          </tr>
                        </thead>
                        {this.state.lab_order_results  && this.state.lab_order_results.widget_data.length > 0 && this.state.lab_order_results.widget_data.map(lab_order_observation => {
                        return (
                              <tbody>
                                <tr style= {{background: 'lightgray' }} >
                                  <td className="text-left" data-toggle="collapse" data-target={'#lab-order-observation-${lab_order_observation.lab_order_observation_guid}'} aria-expanded="true">
                                    {lab_order_observation.text}
                                    <span className="glyphicon glyphicon-chevron-down"></span>
                                    <span className="float-right">Lab: {JSON.parse(lab_order_observation.lab_detail)["SiteID"]} | Ordering Clinician: {JSON.parse(lab_order_observation.lab_detail)["MedicalDirector"]}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="12" className="hiddenRow">
                                    <div className="accordian-body collapse show" id={`lab-order-observation-${lab_order_observation.lab_order_observation_guid}`}>
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr className="info">
                                            <th>Test Name</th>
                                            {(lab_order_observation.order && lab_order_observation.order.test_cd == "4446" && lab_order_observation.text == "CULTURE, AEROBIC BACTERIA ") ? (
                                                <React.Fragment>
                                                  <th>INT</th>
                                                  <th>MIC</th>
                                                </React.Fragment>
                                            ) : (
                                              <th>Results</th>
                                            )}
                                            <th>Previous Results</th>
                                            <th>Reference Range</th>
                                            <th>Status</th>
                                            <th>Observation Note</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {lab_order_observation.lab_order_observation_results  && lab_order_observation.lab_order_observation_results.length > 0 && lab_order_observation.lab_order_observation_results.map(observation_result => {
                                            return (
                                                   
                                                    <tr key={observation_result.guid}>
                                                     {!(lab_order_observation.order && lab_order_observation.order.test_cd == "4446" && observation_result.sequence_id == 1 && observation_result.alternate_text == "ISOLATE") && (
                                                      <>
                                                        { observation_result.observation_value != "DNR" && (
                                                          <>
                                                            <td>{observation_result.alternate_text}</td>
                                                             { lab_order_observation.order &&  lab_order_observation.order.test_cd == "4446" && lab_order_observation.text == "CULTURE, AEROBIC BACTERIA " ? (
                                                                  <React.Fragment>
                                                                     <td dangerouslySetInnerHTML={{ __html: observation_result.display_results_int }} />
                                                                     <td dangerouslySetInnerHTML={{ __html: observation_result.display_results_mic }} />
                                                                  </React.Fragment>
                                                              ) : (
                                                                 <td dangerouslySetInnerHTML={{ __html: observation_result.display_results }} />
                                                              )}
                                                            <td></td>
                                                            <td>&lt;{observation_result.refrence_range}</td>
                                                            <td>{JSON.parse(observation_result.result)["ResultStatus"]}</td>
                                                            <td className="text-left">
                                                              { observation_result.lab_order_observation_result_notes && observation_result.lab_order_observation_result_notes.length > 0 && observation_result.lab_order_observation_result_notes.map(note => {
                                                                return (
                                                                    <div className="white-space-pre">{note.comment}</div>
                                                                    
                                                                     );
                                                                })}
                                                            </td>
                                                          </>
                                                        )}
                                                      </>
                                                      )}
                                                    </tr>
                                              );
                                            })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class ProcedureCode extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Labs Widget",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: ProcedureCode.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "labsComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <LabsComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
    getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}
